import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedNavMenu: "Dashboard",
  activeItem: "Dashboard",
  toggleMenu:true
};
const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setSelectedNavMenu: (state, action) => {
      state.selectedNavMenu = action.payload;
    },
    setActiveItem: (state, action) => {
      state.activeItem = action.payload;
    },
    setToggleMenu:(state,action)=>{
        state.toggleMenu=action.payload;
    }
  },
});

export const { setSelectedNavMenu,setActiveItem, setToggleMenu } = navSlice.actions;
export const selectSelectedNavMenu = (state) => state.nav.selectedNavMenu;
export const selectActiveItem = (state) => state.nav.activeItem;
export const selectedToggleOption=(state)=>state.nav.toggleMenu

export default navSlice.reducer;