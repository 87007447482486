import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
// import Vector from './Vector.svg'
import "./PieChart.css";

const ctx = document.createElement("canvas").getContext("2d");

// Define three linear gradients for use in the chart's background colors.
const gradient1 = ctx.createLinearGradient(0, 0, 0, 175);
gradient1.addColorStop(0, "#00FF29");
gradient1.addColorStop(0, "#40FF6A");
gradient1.addColorStop(1, "#00FFD1");

const gradient2 = ctx.createLinearGradient(0, 0, 0, 175);
gradient2.addColorStop(0, "#71D0F6");
gradient2.addColorStop(1, "#007BC9");

const gradient3 = ctx.createLinearGradient(0, 0, 0, 175);
gradient3.addColorStop(0, "#E440FF");
gradient3.addColorStop(1, "#F55");

const gradient4 = ctx.createLinearGradient(0, 0, 0, 175);
gradient4.addColorStop(0, "#f6b608");
gradient4.addColorStop(1, "#ffda00");

const PieChart = ({ text, data1, data2, data3, data4, total }) => {
  // Define the data for the Doughnut chart.
  const data = {
    datasets: [
      {
        backgroundColor: [gradient1, gradient2, gradient3, gradient4],
        borderColor: " rgba(0, 0, 0, 0)",
        data: [data1, data2, data3, data4],
        offset: 3,
        cutout: "86%",
        borderRadius: 10,
      },
    ],
  };
  //To display text in the center of the chart
  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.font = "500 2.8rem Source Sans Pro";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${total}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
      ctx.font = "500 1rem Source Sans Pro";
      ctx.fillStyle = "white";
      ctx.textBaseline = "top";
      ctx.fillText(
        text,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y + 15
      );
    },
  };
  return (
    <div>
      {
        <Doughnut
        key={ "unique"}
          data={data}
          options={{
            maintainAspectRatio: false,
            animation: {
              duration: 2000,
            },
          }}
          plugins={[textCenter]}
          className="chartCard"
        />
      }
    </div>
  );
};
export default PieChart;
