const getBaseUrl = () => {
  const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_BASE_URL_${env}`];
};

const getApiKey = () => {
  const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_KEY_${env}`];
};

const getApiAnalyticsKey=()=>{
    const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_KEY_ANALYTICS_${env}`];
}

const getBaseAnalyticsURL=()=>{
  const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
  return process.env[`REACT_APP_API_BASE_URL_ANALYTICS_${env}`];
}

const fetchApiData = async (endpoint) => {
  const url = getBaseUrl() + endpoint;
  const key = getApiKey();
  const headers = { 
    "Content-Type": "application/json",
    ZCFKEY: key,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (response.status === 404) {
      return { status: 404 };
    }

    if (!response.ok) {
      throw new Error("Error: Not found!");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const fetchAnalyticsApiData = async (endpoint) => {
  const url = getBaseAnalyticsURL() + endpoint;
  const key = getApiAnalyticsKey();
  const headers = {
    "Content-Type": "application/json",
     ZCFKEY: key,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers,
    });
  
    if (response.status === 404) {
      return { status: 404 };
    }

    if (!response.ok) {
      throw new Error("Error: Not found!");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.log("Error");
  }
};

export const sendRequest = async (endpoint, data) => {
  const url = getBaseUrl() + endpoint;
  const key = getApiKey();
  const headers = {
    "Content-Type": "application/json",
    ZCFKEY: key,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });
 
    if (response.status === 200) {
      const responseData = await response.json();
      return responseData;
    }

    if (!response.ok) {
      throw new Error("Error: Not found!");
    }
  } catch (error) {
    console.error("API Request Error:", error);
    throw new Error(error.message);
  }
};

export default fetchApiData;
