import React from "react";
import { SideNavbar } from "../../Components/sideNavbar/SideNavbar";
import { Reports } from "../../Components/Reports/Reports";
import { DashboardHeader } from "../../Components/Dashboard/dashboardHeader/DashboardHeader";
import { Footer } from "../../Components/Footer/Footer";
import "./reportBody.css";

export const ReportBody = () => {
  return (
    <div className="dashboard-body">
      <DashboardHeader />
      <div className="dashboard-page">
        <SideNavbar />
        <div className="reports-container">
          <Reports />
        </div>
      </div>
      <Footer />
    </div>
  );
};
