// FlipCardBack.js

import React from "react";
import { RiArrowGoBackFill } from "react-icons/ri";

import "../../../views/Dashboard/dashboard.css";
import iButton from "../../../assets/IButton.svg";
import arrowUp from "../../../assets/ArrowUp.svg";
import overload from "../../../assets/Overload.svg";
import distance from "../../../assets/Distance.svg";
import speed from "../../../assets/Speed.svg";
import usage from "../../../assets/Usage.svg";
import avgDistance from "../../../assets/AvgDistance.svg";
import cycle from "../../../assets/Cycle.svg";
import { useSelector } from "react-redux";

const FlipCardBack = ({ handleButtonClick, content }) => {
  const selectedFilter = useSelector((state) => state.filter.selectedFilter);
  let chosenFilter = "";

  switch (selectedFilter) {
    case "Today":
      chosenFilter = "daily";
      break;
    case "Month":
      chosenFilter = "monthly";
      break;
    case "Year":
      chosenFilter = "yearly";
      break;
    default:
      chosenFilter = "";
      break;
  }

  const contentForIButton = {
    iButtonSrc: iButton,
    infoTitles: [
      {
        imgSrc: overload,
        alt: "overload",
        title: "Total OverloadCount",
        description: "Total Overload count of all MOPTros.",
        status: "",
      },
      {
        imgSrc: distance,
        alt: "distance",
        title: "Avg Distance b/w 2 Picks",
        description: `Indicates the average distance that MOPTros travel between two pick points on a daily basis.`,
        status: ".",
        name:"TwoPick"
      },
      {
        imgSrc: speed,
        alt: "Avg Speed",
        title: "Avg Speed",
        description: `Indicates the average speed of MOPTros on daily basis.`,
      }, 
       {
        imgSrc: "",
        alt: "Avg Utilization",
        title: "Avg Utilization",
        description: "Avg Utilization indicates the percentage (%) and hours (hrs) from the time the MOPTro first begins use to the time it is last used on a daily basis.",
        className: "color-box-2",
        unit: "(%)",
      },
      // {
      //   imgSrc: usage,
      //   alt: "Avg Vehicle Running Time",
      //   title: "Avg Vehicle Running Time",
      //   description: `Indicates the average duration of daily usage of MOPTros.`,
      //   status: "",
      // },
    
      {
        imgSrc: avgDistance,
        alt: "Avg distance",
        title: "Avg Distance Travelled",
        description: `Indicates the average distance that MOPTros travel on a daily basis.`,
        formula: " (Total Distance Travelled / Number of MOPTros).",
        unit: "(km)",
        status:""
      },
      {
        imgSrc: cycle,
        alt: "start-stop cycle",
        title: "Avg Start-Stop Cycles",
        description: `Indicates Average number of start-stop cycles of MOPTros on a daily basis.`,
        formula: " (Total Start-stop Cycles / Number of MOPTros).",
        unit: "(count)",
        status: ".",
      },
      {
        imgSrc: arrowUp,
        alt: "moptro uptime",
        title: "MOPTro Uptime",
        description: "Indicates the overall availability of MOPTros.",
      },
      {
        imgScr: "",
        alt: "",
        title: "In Use",
        description:
          "Indicates the number of MOPTros that are being used presently",
        className: "color-box-1",
        status: "Status: ",
      },
      {
        img: "",
        alt: "",
        title: "Idle",
        description:
          "Indicates the number of MOPTros that are not being used presently",
        className: "color-box-2",
        status: ".",
      },
      {
        img: "",
        alt: "",
        title: "Offline",
        description:
          "Indicates the number of MOPTros that are not connected to internet",
        className: "color-box-3",
        status: ".",
      },
      {
        img: "",
        alt: "",
        title: "In Service",
        description: "Indicates the number of MOPTros that are in service",
        className: "color-box-4",
        status: ".",
      },
    ],
  };

  const filteredTitles = contentForIButton.infoTitles.filter((info) =>
    content.includes(info.title)
  );

  return (
    <div className="flip-card-back">
      <div className="heading">
        INFO
        <div className="title">
          <RiArrowGoBackFill
            className="backArrow"
            onClick={handleButtonClick}
          />
        </div>
      </div>
      {filteredTitles.map((info, index) => (
        <div key={index} className={info.name? `${info.name} info-title`:"info-title"}>
          {info.status === "Status: " ? (
            <div className="info-status"> {info.status}</div>
          ) : (
            <div className="no-status"></div>
          )}
          <div className="info-title-container">
            {info.title}: <br />
          </div>
          <div className="info-description">{info.description}</div>
          <br />
          <div className={info.formula ? "info-formula" : " "}>
            {info.formula ? "Formula " : " "}
            {/* <div className="info-unit"> {info.unit}</div> */}
          </div>
          {info.formula}
          {/* <br /> */}
          {info.status ? "" : <hr></hr>}
        </div>
      ))}
    </div>
  );
};

export default FlipCardBack;
