import React, { useEffect, useState } from "react";
import {
  setSelectedNavMenu,
  selectSelectedNavMenu,
  setToggleMenu,
  selectActiveItem,
  setActiveItem,
} from "../../redux/slice/sideNavbarSlice";
import { useSelector, useDispatch } from "react-redux";

import {
  DashboardOutlined,
  BarChartOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import DropdownMenu from "../DropdownMenu";
import dashboardImg from "../../assets/Dashboard.svg";
import dashboardImgActive from "../../assets/Dashboard-active.svg";
import reportActive from "../../assets/ReportActive.svg";
import Moptro from "../../assets/Moptro.svg"
import user from "../../assets/User.svg";
import charts from "../../assets/Charts.svg";
import chartsActive from "../../assets/ChartsActive.svg";
import userActive from "../../assets/UserActivep.svg"
import report from "../../assets/Report.svg";
import active from "../../assets/Active.svg";
import { ReactComponent as Report } from "../../assets/Report.svg";
// import { ReactComponent as Moptro } from "../../assets/Moptro.svg";
import { ReactComponent as Info } from "../../assets/Info.svg";
import "./sideNavbar.css";
import { useNavigate } from "react-router-dom";
export const SideNavbar = () => {
  const navigate = useNavigate();
  const selectedNavMenu = useSelector(selectSelectedNavMenu);
  const activeMenu=useSelector(selectActiveItem)
  const dispatch = useDispatch();
  const handleMenuClick = (item) => {
    dispatch(setSelectedNavMenu(item));
    dispatch(setActiveItem(item))
    dispatch(setToggleMenu(false));
    navigate(`/app/${item}`);
  };
  const { Sider } = Layout;
  return (

    <Sider>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={activeMenu===0?["Dashboard"]:[activeMenu]}>
        <Menu.Item
          key="Dashboard"
          icon={activeMenu==="Dashboard" || activeMenu===0?<img src={dashboardImgActive}/>:<img src={dashboardImg}/>}
          onClick={() => handleMenuClick("Dashboard")}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="Charts"
          icon={activeMenu==="Charts"? <img src={chartsActive}/>:<img src={charts}/>}
          onClick={() => handleMenuClick("Charts")}
        >
          Charts
        </Menu.Item>
        <Menu.Item
          key="Report"
          icon={activeMenu==="Report"? <img src={reportActive}/>:<img src={report}/>}
          onClick={() => handleMenuClick("Report")}
        >
          Report
        </Menu.Item>
        <Menu.Item
          key="Moptro"
          icon={activeMenu==="Moptro"?<img src={userActive}/>:<img src={user}/>}
          onClick={() => handleMenuClick("Moptro")}
        >
          Users
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
