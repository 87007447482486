import React from "react";
import "./footer.css";
import greendzineLogo from "../../assets/GreendzineLogo.svg";
export const Footer = () => {
  return (
    <div className="footer">
      <img src={greendzineLogo} alt="Greendzine logo " />
      <div>
        A Product of Greendzine Technologies Pvt. Ltd. All rights reserved
      </div>
    </div>
  );
};
