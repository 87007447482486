import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import "./PieChart.css";
import doughnutGraph from "../../assets/doughnutGraph.svg";

const ctx = document.createElement("canvas").getContext("2d");

// Define three linear gradients for use in the chart's background colors.

const gradient1 = ctx.createLinearGradient(0, 0, 0, 175);
gradient1.addColorStop(0, "#666666");
gradient1.addColorStop(0, "#5C5C5C");
gradient1.addColorStop(1, "#404040");

const gradient2 = ctx.createLinearGradient(0, 0, 0, 175);
gradient2.addColorStop(0, "#71D0F6");
gradient2.addColorStop(1, "#00FFDB");

const PieChart2 = ({ text, data1, data2, percent }) => {
  // Create a state variable to store the percent value

  // Define the data for the Doughnut chart.
  const data = {
    datasets: [
      {
        backgroundColor: [gradient2, gradient1],
        borderColor: "rgba(0, 0, 0, 0)",
        data: [data1, data2],
        offset: 3,
        cutout: "85%",
        borderRadius: 10,
      },
    ],
  };

  // To display text in the center of the chart
  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.font = "700 2rem Source Sans Pro";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${percent}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };


  return (
    <div>
      {
        <Doughnut
          key={"unique"}
          data={data}
          options={{
            maintainAspectRatio: false,
            animation: {
              duration: 2000,
            },
          }}
          plugins={[textCenter]}
          className="chartCard2"
        />
      }
    </div>
  );
};

export default PieChart2;
