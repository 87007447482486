import React, { useCallback } from "react";
import analyticsLogoSingle from "../../../assets/AnalyticsLogoSingle.svg";
import bell from "../../../assets/Bell.svg";
import profile from "../../../assets/Profile.svg";
import GDT from "../../../assets/GreendzineRoundLogo.svg";
import DropdownMenu from "../../DropdownMenu";
import "./dashboardHeader.css";
import { redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveItem,
  setSelectedNavMenu,
} from "../../../redux/slice/sideNavbarSlice";
import { setCustomer, setID, setName } from "../../../redux/slice/userSlice";
import { setAuth } from "../../../redux/slice/authSlice";

export const DashboardHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.name);

  const handleLogout = () => {    
    dispatch(setSelectedNavMenu("Dashboard"));
    dispatch(setActiveItem("Dashboard"));
    localStorage.clear();
    dispatch(setAuth(false))
  };
  return (
    <div className="dashboard-header">
      <div className="header-title-container">
        <img src={analyticsLogoSingle} alt="Logo" />
        <div className="header-title">W2H Analytics</div>
      </div>
      <div className="profile-container">

        <DropdownMenu
          title=""
          items={["Logout"]}
          img={username === "Greendzine" ? GDT : profile}
          imgActive={username === "Greendzine" ? GDT : profile}
          onMenuItemClick={() => handleLogout()}
          className={ "navbar-inactive"}
          user={username}
        />{" "}
      </div>

      {/* </div> */}
    </div>
  );
};
