import Chart from "react-apexcharts";
import "./graph.css";
import dayjs from "dayjs";

const GroupedBarChart = ({ name, cat, ser, tooltip, option, message, days }) => {
  let tooltipName = "";
  let unit = "";
  if (option === "util") {
    tooltipName = "Distance Travelled  ";
    unit = "km";
  } else if (option === "velocity") {
    tooltipName = "Avg speed  ";
    unit = " km/h";
  } else {
    tooltipName = "Usage  ";
    unit = " mins";
  }

  const color = [
    "#1EFFED",
    "#167a9e",
    "#6C31FF",
    "#C210DB",
    "#E8A6FF",
    "#0be092",
  ];
  // Legends
  // const vins = [
  //   "Vehicle № 1",
  //   "Vehicle № 2",
  //   "Vehicle № 3",
  //   "Vehicle № 4",
  //   "Vehicle № 5",
  //   "Vehicle № 6",
  //   "Vehicle № 7",
  //   "Vehicle № 8",
  // ];
  function formatDate(dateString) {
    const date = dayjs(dateString);
    let temp = date.format('D MMM');

    return temp;
  }

  let x_axis = [];
  if (cat) {
    for (let i = 0; cat.length === 29 ? i <= cat.length : i < cat.length; i++) {
      x_axis.push(formatDate(cat[i]));
    }
  }

  const options = {
    chart: {
      id: "grouped-bar-chart",
      background: "rgba(0, 0, 0, 0)",
      opacity: "0.22",
      foreColor: "#FEFEFE",
      toolbar: {
        show: true,

        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },

        export: {
          csv: {
            // Change here for the file name
            filename: "MOPTro_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
          },
          svg: {
            filename: "MOPTro_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
          },
          png: {
            filename: "MOPTro_data",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
          },
        },
        autoSelected: "zoom",
      },
    },
    xaxis: {
      categories: x_axis,
      tickAmount:5,
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "0.825rem",
          fontFamily: "Montserrat",
          fontWeight: 400,
        }
      }
    },
    colors: color,

    fill: {
      colors: color,
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        gradientToColors: color,
      },
    },
    grid: {
      show: true,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
          opacity: 0,
        },
      },
      borderColor: "#90A4AE",
      strokeDashArray: 2,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        grouped: true,
        zero: true,
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: name,
        offsetY: 14,
        style: {
          fontSize: "0.9rem",
          fontFamily: "Montserrat",
          fontWeight: 400,
          cssClass: "apexCharts-y-axis-title",
        },
      },
      tickAmount: 3,
      tickPlacement: 'between'
    },
    noData: {
      text: "NO DATA AVAILABLE ",
      align: "center",
      verticalAlign: "top",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#FFF",
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class = "tooltip">' +
          `<b> ${tooltipName} </b>  :` +
          "  " +
          tooltip[seriesIndex][dataPointIndex] +
          unit +
          "" +
          "</div>"
        );
      },
    },
  };

  // const series = ser;
  let series = [];
  message === "NO DATA AVAILABLE FOR THE USER"
    ? (series = [{ data: [] }])
    : (series = ser);
  //  series = cat ? ser : [{ data: [] }];
  return (
    <>
      <div className="first-map">
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="150"
          key={JSON.stringify(series)} //Force rendering the chart because custom tooltip function was not re-rendering on series update
        />
      </div>
    </>
  );
};

export default GroupedBarChart;
