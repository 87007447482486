import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  pickerDetail: null,
};
const moptroSlice = createSlice({
  name: "MOPTro",
  initialState,
  reducers: {
    setMoptroData: (state, action) => {
      state.data = action.payload;
    },
    setPickerDetail: (state, action) => {
      state.pickerDetail = action.payload;
    },
  },
});
export const { setMoptroData, setPickerDetail } = moptroSlice.actions;
export default moptroSlice.reducer;
