// Copyright @ 2023 Greendzine Technologies Pvt ltd. All rights reserved. For Internal use only.

import React, { useState } from "react";
import { useEffect } from "react";
import { Form, DatePicker, Select,Space } from "antd";
import moment from "moment";
import "../Dashboard/Chart/timerender.css";
import dayjs from "dayjs";
import { SELECTION_ALL } from "antd/es/table/hooks/useSelection";

const { RangePicker } = DatePicker;

const RangeDatePicker = ({
  onDateChange,
  warn,
  onShiftChange,
  btnClicked,
  selectedDates,
  page,
  datePassed
}) => {
  const defaultDay1 = dayjs().subtract(20, "days").format("YYYY-MM-DD");
  const defaultDay2 = dayjs().format("YYYY-MM-DD");
  const [dates, setDates] = useState(datePassed);
  let [value, setValue] = useState(0);
  const handleDateChange = (dates, dateStrings) => {
    setDates(dates);
    onDateChange(dateStrings);
  };
  const disabledDateRange = (current, dates) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    // Disable dates before April 1st, 2023
    if (current && current < moment("2023-05-15").startOf("day")) {
      return true;
    }
  };
  return (
    <div className="time_pick">
      <div className="temp_pick">
        {page === "Charts" ? (
          <div className="lable">
            Select dates: <p>(max 21 days)</p>
          </div>
        ) : (
          <Form.Item
            label={`Select Dates`}
            labelCol={{ style: { color: "#FFFFFF" } }}
          ></Form.Item>
        )}
<Space direction="vertical" size={12}>
  <RangePicker
          value={dates}
          onClick={() => setDates([])}
          disabledDate={disabledDateRange}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          className="custom-range-picker"
          allowClear={true}
        />
</Space>
        
      </div>
    </div>
  );
};

export default RangeDatePicker;
