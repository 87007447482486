import React from "react";
import "./loginBody.css";
import wasp from "../../assets/Group.svg";
import snail from "../../assets/Snail.svg";
import lite from "../../assets/Lite.svg";
import litePlus from "../../assets/LitePlus.svg";
import litePlus14 from "../../assets/LiteFT.svg";
import moptroB from "../../assets/MoptroB.svg";

export const Products = () => {
  return (
    <div className="products-container">
      <div className="product">
        <img src={wasp} alt="Wasp" />
        <p>WASP®</p>
      </div>
      <div className="product">
        <img src={snail} alt="Snail" />
        <p>SNAIL®</p>
      </div>
      <div className="product">
        <img src={lite} alt="Lite" />
        <p>LITE®</p>
      </div>
      <div className="product">
        <img src={litePlus} alt="Lite+" />
        <p>LITE®+</p>
      </div>
      <div className="product">
        <img src={litePlus14} alt="Lite+14" />
        <p>LITE®+ 14 TOTE</p>
      </div>
      <div className="product">
        <img src={moptroB} alt="MoptroB" />
        <p>MOPTro® B</p>
      </div>
    </div>
  );
};
