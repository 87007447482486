import React,{useEffect} from "react";
import { useSelector } from "react-redux";
import { SideNavbar } from "../../../Components/sideNavbar/SideNavbar";
import { Footer } from "../../../Components/Footer/Footer";
import { DashboardHeader } from "../../../Components/Dashboard/dashboardHeader/DashboardHeader";
import { ChartBody } from "../../../Components/Dashboard/Chart/ChartBody";
import Body from "../../../Components/Dashboard/Chart/RepoBody";
import "./charts.css";
import { useNavigate } from "react-router-dom";

export const Charts = () => {
  const auth = useSelector((state) => state.auth.isAuth);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!auth) {
  //     navigate("/app");
  //   }
  // }, []);
  return (
    <div className="chart-body">
      <DashboardHeader />
      <div className="chart-body-container">
        <SideNavbar />
        <Body/>
      </div>

      <Footer />
    </div>
  );
};
