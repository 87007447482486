import React from "react";
import "./loginBody.css";

import moptroLogo from "../../assets/MOPTroLogo1.svg";

export const Header = () => {
  return (
    <div>
      <header>
        <img src={moptroLogo} className="header-img" alt="MOPTro Logo" />
        <div className="header-title">W2H Connect Platform</div>{" "}
      </header>
    </div>
  );
};
