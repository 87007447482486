import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import jsonData from "../../../users.json";
import { ReactComponent as FilterCust } from "../../../assets/Filter.svg";

export const UserDropdown = ({ selectedOption, filterUser }) => {
  const allUsers = useSelector((state) => state.user.customer);
  const loginID = useSelector((state) => state.user.id);
  const dispatch = useDispatch();

  const [value, setValue] = useState();

  const filteredUsers = allUsers?.filter((user) => user.locationId !== loginID);
  let options;
  if (filterUser) {
    options = filteredUsers?.map((user) => ({
      value: user.locationId,
      label: `${user.customer_name} - ${user.location_name}`,
    }));
  }
  const handleChange = (e) => {
    {
      e ? selectedOption(e) : selectedOption(loginID);
    }
  };

  let selectStyles = {
    minWidth: 120,
    maxWidth: 300,
  };

  if (window.matchMedia("(width: 1280px)").matches) {
    selectStyles.maxWidth = 245;
  }
  return (
    <Select
      style={selectStyles}
      options={options}
      popupMatchSelectWidth={false}
      mode="single"
      allowClear
      value={value}
      onChange={handleChange}
      placeholder={<>Customers</>}
    />
  );
};

export default UserDropdown;
