import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "../../views/Dashboard/Dashboard";
import Live from "../../assets/Live.svg";
import sheild from "../../assets/Sheild.svg";
import speed from "../../assets/Speed.svg";
import usage from "../../assets/Usage.svg";
import overload from "../../assets/Overload.svg";
import distance from "../../assets/Distance.svg";
import avgDistance from "../../assets/AvgDistance.svg";
import cycle from "../../assets/Cycle.svg";
import arrowUp from "../../assets/ArrowUp.svg";
import PieChart from "../pieChart/PieChart";
import PieChart2 from "../pieChart/PieChart2";
import doughnutGraph from "../../assets/doughnutGraph.svg";
import iButton from "../../assets/IButton.svg";
import Loader from "../Loader/Loader";
import FilterCust from "../../assets/Filter.svg";

//importing api
import fetchApiData, { sendRequest,fetchAnalyticsApiData } from "../../service/appUtils";
import { useActionData, useNavigate } from "react-router-dom";
import { IButton } from "./IButton";
import FlipCardBack from "./FlipCard/FlipCardBack";
import { setSelectedFilter } from "../../redux/slice/filterSlice";
import { UserDropdown } from "./UserFilter/UserDropdown";
import SelectAntD from "./Chart/SelectAntD";
import { setID, setCustomer, setName } from "../../redux/slice/userSlice";

import users from "../../views/userProfile.json"
import { setAuth } from "../../redux/slice/authSlice";

export const DashboardBody = () => {
  const [activeItem, setActiveItem] = useState("Today");
  const [dashboardData, setDashboardData] = useState(null);
  const [liveDashboardData, setLiveDashboardData] = useState(null);
  const [authenticated, setauthenticated] = useState(null);
  const [filter, setFilter] = useState("Today");
  const [chartText, setChartText] = useState(0);
  const [pieChartKey, setPieChartKey] = useState(0);
  const [loader, setLoader] = useState(Loader);
  const [moptroUptime, setMoptroUptime] = useState(0);
  const ID = useSelector((state) => state.user.id);
  const username = useSelector((state) => state.user.name);
  const [userId, setUserId] = useState();
 const [contactNumber,setContactNumber]=useState(ID);
  //flip
  const dispatch = useDispatch();
  // const [isFlipped, setIsFlipped] = useState(false);
  const [isFlipped, setIsFlipped] = useState({
    card1: false,
    card2: false,
    card3: false,
    card4: false,
    // Add more cards as needed
  });

  const navigate = useNavigate();
  const [adminUser, setAdminUser] = useState();
  const [adminUserId, setAdminUserId] = useState();
  const loggedInUser = localStorage.getItem("authenticated");
  const admin = useSelector((state) => state.user.name);
  // window.onpopstate = () => {
  //   navigate("/app/Dashboard");
  // };

  const handleButtonClick = (cardNumber) => {
    setIsFlipped((prevIsFlipped) => ({
      ...prevIsFlipped,
      [cardNumber]: !prevIsFlipped[cardNumber],
    }));
  };
  const handleUserSelect = (e) => {
   dispatch(setID(e));
    fetchLiveData(e);
    fetchData(e, filter);
    fetchMoptroUptime(e,filter);
    setUserId(e);
  };
  var duration = moment.duration(dashboardData?.averageUsage, "hours");
  // Format the duration as HH:MM
  var formattedTime = moment.utc(duration.asMilliseconds()).format("HH:mm");

  const handleClick = (index) => {
    setActiveItem(index);
    setFilter(index);
    dispatch(setSelectedFilter(index));
    setLoader(true);
    fetchData(ID, index);
    fetchMoptroUptime(ID, index)
    //setChartText(dashboardData.utilization);
  };

    useEffect(() => {
      if(username=="admin"){
         const fetchUserDetails = async () => {
      const endpoint = "adminUser";
      try {
        const response = await fetchAnalyticsApiData(endpoint);

        if (response.status === 200 && response.data) {
          setAdminUser(response.data.email_id);
          setAdminUserId(response.data.user_id);
          setUserId(response.data.locationId)
          dispatch(setName("admin"))
        }
        return response;
      } catch (error) {
        console.log("API Request Error:", error);
        throw error;
      }
    };

    const insertUserDetails = async () => {
      const endpoint = "insertAdmin";
      const data = {
        adminName: adminUser,
        adminPassword: adminUserId,
      };
      try {
        const response = await sendRequest(endpoint, data);
        if (response.status === 200) {
          dispatch(setID(response.contact_number))
          setContactNumber(response.contact_number);
        }
        return response;
      } catch (error) {
        console.log("API Request Error:", error);
        throw error;
      }
    };
    fetchUserDetails().then(() => {
      if (adminUser !== undefined && adminUserId !== undefined) {
        insertUserDetails();
      } else {
        // console.log("ADD LOADER");
      }
    });
      }
   
  }, [adminUser, adminUserId]);

  const fetchLiveData = async (id) => {
    const liveDataEndPoint = `liveDashboard?id=${id}`;

    try {
      const response = await fetchApiData(liveDataEndPoint);
      if (response.status === 200) {
        setLiveDashboardData(response.data);
        setPieChartKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };
  const fetchData = async (id, dashboardFilter) => {
    const endpoint = `filterDashboard?id=${id}&dashboardType=${dashboardFilter}`;
    setLoader(true);
    try {
      const response = await fetchApiData(endpoint);
        
      if (response?.status === 200) {
        setDashboardData(response.data);
        setChartText(response.data.utilization);
        //  dispatch(setAuth(true));
      }
      setLoader(false);
      return response;
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const fetchMoptroUptime = async (id,dashboardFilter) => {
    const moptroEndpoint = `moptroUptime?locationId=${id}&dashboardType=${dashboardFilter}`;
    try {
      const response = await fetchApiData(moptroEndpoint);
      if (response.status === 200) {
        setMoptroUptime(response.data);
      }
      return response;
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const fetchUserDetails = async () => {
    const endpoint = "users";
    try {
      const response = await fetchApiData(endpoint);
      dispatch(setCustomer(response.data));
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  };
useEffect(() => {
  if (loggedInUser) {
    setauthenticated(loggedInUser);
  }
  if (username==="admin") {
    fetchUserDetails();
    fetchData(ID, filter);
    fetchMoptroUptime(ID,filter);
    fetchLiveData(ID);
  }else if (ID) {
    fetchData(ID, filter);
    fetchMoptroUptime(ID,filter);
    fetchLiveData(ID);
  }
}, [ID]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(userId){
        fetchLiveData(userId);
      }else{
        fetchLiveData(contactNumber)
      }
      
    }, 30000,contactNumber);
    return () => clearInterval(intervalId);
  }, [authenticated, userId]);

  if (dashboardData === null) {
    return (
      <div style={{ display: "flex" }} id="main-alert-box">
        {loader ? <span className="loader"></span> : null}
      </div>
    );
  }

  return (
    <div className="dashboard-body-container">
      <div className="filterUser">
        <div className="filter-btn-container">
          <button
            onClick={() => handleClick("Today")}
            className={activeItem === "Today" ? "active" : ""}
          >
            Today
          </button>
          <button
            onClick={() => handleClick("LastMonth")}
            className={activeItem === "LastMonth" ? "active" : ""}
          >
            Last 30 Days
          </button>
          <button
            onClick={() => handleClick("LastYear")}
            className={activeItem === "LastYear" ? "active" : ""}
          >
            Last 365 Days
          </button>
        </div>
        <div className="filter-user-container">
          {username==="admin" ? (
            <div className="filter-icon-container">
              {/* <div>Filter: </div> */}
              {/* <img src={FilterCust} alt="filter" /> */}
              <UserDropdown
                filterUser={true}
                selectedOption={handleUserSelect}
              />
            </div>
          ) : (
            <div className="filter_user_container_hiddden">
              <UserDropdown
                filterUser={true}
                selectedOption={handleUserSelect}
              />
            </div>
          )}
        </div>
      </div>

      {loader ? (
        <div style={{ display: "flex" }} id="main-alert-box">
          <span className="loader"></span>
        </div>
      ) : null}
      <div className="statistics-container">
        <div className="live-status-container">
          <div className={`flip-card ${isFlipped.card1 ? "flipped" : ""} `}>
            <div className="flip-card-inner">
              <div className={`live-status ${isFlipped.card1 ? "hide" : ""} `}>
                <div className="uptime">
                  <div className="avg-speed-container">
                    <div className="data-unavailable">
                      <img src={arrowUp} alt="arrow" />
                      {moptroUptime} %
                    </div>
                    <div
                      className="title"
                      onClick={() => handleButtonClick("card1")}
                    >
                      <img src={iButton} alt="iButton" />
                    </div>
                    <div className="headings">MOPTro Up Time</div>
                  </div>
                  <hr />
                </div>

                <div className="graph">
                  <img src={Live} alt="live" className="live" />

                  <PieChart
                    key={pieChartKey}
                    // key={filter}
                    text={`Vehicles`}
                    data1={liveDashboardData?.vehicleRunningCount}
                    data2={liveDashboardData?.notRunningCount}
                    data3={liveDashboardData?.servicePendingCount}
                    data4={liveDashboardData?.notConnectedCount}
                    total={
                      liveDashboardData?.deployedVehilces
                        ? liveDashboardData?.deployedVehilces
                        : dashboardData?.vehicleCount
                    }
                  />
                </div>

                {/* <div className="headings">MOPTro Up Time</div> */}
                <div className="live-vehicle-status">
                  <div className="live-vehicle-status-container">
                    <div className="color-box-1"></div>
                    <div className="status-title">In Use</div>
                    <div className="statistics">
                      {liveDashboardData?.vehicleRunningCount}
                    </div>
                  </div>
                  <div className="live-vehicle-status-container">
                    <div className="color-box-2"></div>
                    <div className="status-title">Idle</div>
                    <div className="statistics">
                      {liveDashboardData?.notRunningCount}
                    </div>
                  </div>
                  <div className="live-vehicle-status-container">
                    <div className="color-box-4"></div>
                    <div className="status-title">Offline</div>
                    <div className="statistics">
                      {liveDashboardData?.notConnectedCount}
                    </div>
                  </div>
                  <div className="live-vehicle-status-container">
                    <div className="color-box-3"></div>
                    <div className="status-title">In Service</div>
                    <div className="statistics">
                      {liveDashboardData?.servicePendingCount}
                    </div>
                  </div>
                </div>
              </div>
              <FlipCardBack
                handleButtonClick={() => handleButtonClick("card1")}
                content={[
                  "MOPTro Uptime",
                  "In Use",
                  "Idle",
                  "Offline",
                  "In Service",
                ]}
              />
            </div>
          </div>

          <div className={`utilization flip-card ${isFlipped.card2 ? "flipped" : ""}  `}>
            <div className="flip-card-inner">
              <div className={`status ${isFlipped.card2 ? "hide" : ""} `}>
                <div className="utilization">
                  <div className="graph">
                    {/* <img src={graph} alt="graph" /> */}
                    <PieChart2
                      text={` `}
                      percent={" "}
                      data2={chartText > 100 ? "0" : 100 - chartText}
                      data1={chartText}
                      // percent={`${chartText.toFixed(1)}%`}
                    />
                    <div className="utilization-percent-container">
                      <div className="utilization-percent">
                        {dashboardData?.utilization}%
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <img
                      src={iButton}
                      alt="iButton"
                      onClick={() => handleButtonClick("card2")}
                    />
                  </div>
                  <div className="headings">Avg Utilization</div>
                  <hr />
                </div>

                <div className="distance">
                  <div className="avg-speed-container">
                    <div className="icon-container">
                      <img src={usage} alt="average distance" />
                      <div className="json">
                        <div className="data">
                          {formattedTime}
                        </div>
                        <div className="unit">hrs</div>
                      </div>
                    </div>
                    <div className="headings">Avg Utilization (time) </div>
                  </div>
                  <hr />
                </div>
                <div className="cycle">
                  <div className="avg-speed-container">
                    <div className="icon-container">
                      <img src={cycle} alt="cycle" />
                      <div className="json">
                        <div className="data">
                          {dashboardData?.averageStartStop}
                        </div>
                      </div>
                    </div>
                    <div className="headings">Avg Start-Stop Cycles</div>
                  </div>
                </div>
              </div>
              <FlipCardBack
                handleButtonClick={() => handleButtonClick("card2")}
                content={[
                  "Avg Utilization",
                  "Avg Vehicle Running Time",
                  "Avg Start-Stop Cycles",
                ]}
              />
            </div>
          </div>
        </div>
        <div className="status-grid-container">
          <div
            className={`flip-card-grid1 ${isFlipped.card3 ? "flipped" : ""} `}
          >
            <div className="flip-card-inner">
              <div className={`grid1 ${isFlipped.card3 ? "hide" : ""}`}>
                <div className="avg-speed-container">
                  <div className="icon-container">
                    <img src={speed} alt="speed" />
                    <div className="json">
                      <div className="data">{dashboardData?.averageSpeed}</div>
                      <div className="unit">km/hr</div>
                    </div>
                  </div>
                  <div className="title">
                    <img
                      src={iButton}
                      alt="iButton"
                      onClick={() => handleButtonClick("card3")}
                    />
                  </div>
                  <div className="headings">Avg Speed</div>
                </div>
                <hr />
                <div className="vehicle-usage-container">
                  <div className="icon-container">
                    <img src={avgDistance} alt="usage" />
                    <div className="json">
                      <div className="data">{dashboardData?.averageDistance}</div>
                      <div className="unit">km</div>
                    </div>
                  </div>
                  <div className="title"></div>
                  <div className="headings">Avg Distance Travelled</div>
                </div>
              </div>
              <FlipCardBack
                handleButtonClick={() => handleButtonClick("card3")}
                content={["Avg Speed", "Avg Distance Travelled"]}
              />
            </div>
          </div>

          <div
            className={`info flip-card-grid2 ${isFlipped.card4 ? "flipped" : ""}`}
          >
            <div className="flip-card-inner">
              <div className={`grid2 ${isFlipped.card4 ? "hide" : ""}`}>
                <div className="avg-speed-container">
                  <div className="icon-container">
                    <img src={overload} alt="overload" />
                    <div className="json-grid2">
                      <div className="data">{dashboardData?.overloadCount}</div>
                    </div>
                  </div>
                  <div className="title">
                    <img
                      src={iButton}
                      alt="iButton"
                      onClick={() => handleButtonClick("card4")}
                    />
                  </div>
                  <div className="headings">Total Overload Count</div>
                </div>
                <hr />
                <div className="vehicle-usage-container">
                  <div className="icon-container">
                    <img src={distance} alt="distance" />
                    <div className="json">
                      <div className="data">
                        {dashboardData?.twoPicksDistance}
                      </div>
                      <div className="unit">m</div>
                    </div>
                  </div>
                  <div className="headings">Avg Distance b/w 2 Picks</div>
                </div>
              </div>
              <FlipCardBack
                handleButtonClick={() => handleButtonClick("card4")}
                content={["Total OverloadCount", "Avg Distance b/w 2 Picks"]}
              />
              {/* <div className="flip-card-back">
                <div className="heading">
                  Info
                  <div className="title">
                    <img
                      src={iButton}
                      alt="iButton"
                      onClick={handleButtonClick}
                      className="info-button"
                    />
                  </div>
                </div>
                <div className="info-title">
                  <div className="info-title-container">
                    <img
                      src={overload}
                      alt="overload"
                      className="overload-info-img"
                    />
                    Total Overload Count:{" "}
                  </div>
                  Total overload count of all the vehicles
                </div>
                <hr></hr>
                <div className="info-title">
                  <div className="info-title-container">
                    <img
                      src={distance}
                      alt="distance"
                      className="overload-info-img"
                    />
                    Avg Distance b/w 2 Picks:{" "}
                  </div>
                  It is the ratio of average distance to average start stop
                  cycle
                </div>
              </div> */}
            </div>
          </div>

          <div className="grid3">
            <div className="heading-container">
              <img src={doughnutGraph} alt="warrenty" className="icon" />
              <div className="grid3-footer">IOT Connectivity Status</div>
              <img src={Live} alt="" />
              <hr />
            </div>

            <div className="grid-3-status-container">
              <div className="grid3-graph">
                <PieChart2
                  key={pieChartKey}
                  // key={filter}
                  text={` `}
                  data2={liveDashboardData?.notConnectedCount}
                  data1={liveDashboardData?.connectedCount}
                  percent={" "}
                  className="chartCard"
                />
                <img src={doughnutGraph} alt="" />
              </div>
              <div className="grid-3-status">
                <div className="connectivity">
                  <div className="indicator-connected"></div>
                  <div className="connection-name">Online</div>

                  <div className="connected-devices">
                    {liveDashboardData?.connectedCount}
                  </div>
                </div>
                <div className="connectivity">
                  <div className="indicator-disconnected"></div>
                  <div className="connection-name">Offline</div>

                  <div className="connected-devices">
                    {liveDashboardData?.notConnectedCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid4">
            <div className="heading-container">
              {/* <img src={Live} alt="" /> */}
            </div>
            <div className="header-title">
              <img src={sheild} alt="warrenty" />
              Warranty Status
            </div>
            <hr />
            <div className="warrenty-status-details">
              <div className="warranty">
                <div className="warranty-name">Under Warranty</div>
                <div className="warranty-data">
                  {dashboardData?.vehicleUnderWarranty}
                </div>
              </div>
              <div className="warranty">
                <div className="warranty-name">Expiring Soon</div>
                <div className="warranty-data">
                  {dashboardData?.vehicleWarrantyExpiring}
                </div>
              </div>
              <div className="warranty">
                <div className="warranty-name">Warranty Expired</div>
                <div className="warranty-data">
                  {dashboardData?.vehicleExpired}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
