// Copyright @ 2023 Greendzine Technologies Pvt ltd. All rights reserved. For Internal use only.

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pickSeries: [],
  utilSeries: [],
  usageSeries: [],
  cate: [],
  distanceTooltip: [],
  speedTooltip: [],
  usageTooltip: [],
};

const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setPick: (state, action) => {
      state.pickSeries = action.payload;
    },
    setUtil: (state, action) => {
      state.utilSeries = action.payload;
    },
    setUsage: (state, action) => {
      state.usageSeries = action.payload;
    },
    setCate: (state, action) => {
      state.cate = action.payload;
    },
    setDist: (state, action) => {
      state.distanceTooltip = action.payload;
    },
    setSpeedTooltip: (state, action) => {
      state.speedTooltip = action.payload;
    },
    setUsageTooltip: (state, action) => {
      state.usageTooltip = action.payload;
    },
  },
});

export default graphSlice.reducer;
export const {
  setPick,
  setUtil,
  setCate,
  setUsage,
  setDist,
  setSpeedTooltip,
  setUsageTooltip,
} = graphSlice.actions;
