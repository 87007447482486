import { React, useState, useEffect } from "react";
import "./chartBody.css";
import "./repobody.css";
import { NoDataDashboard } from "./NoDataDashboard";
import Graphs from "./Graphs";
import DateTimePicker from "../Chart/TimeRender";
import RangeDatePicker from "../../Reports/RangeDatePicker";
import { useSelector, useDispatch } from "react-redux";
import {
  setPick,
  setUtil,
  setCate,
  setUsage,
  setDist,
  setSpeedTooltip,
  setUsageTooltip,
} from "../../../redux/slice/graphSlice";
import ApiService from "../../../service/appUtils";
import SelectAntD from "../Chart/SelectAntD";
import { setDashOption } from "../../../redux/slice/dashboardSlice";
import dayjs from "dayjs";

const Body = () => {
  const id = useSelector((state) => state.user.id);
  const presentDate = dayjs().subtract(20,'days');
  const last30Day=dayjs()
  let param = {
    id: id,
    startDate: dayjs().subtract(20,'days').format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  };
  const [clicked, setClicked] = useState(false);
  const [VINOpen, setVINOpen] = useState(false);
  const [message, setMessage] = useState();
  const [selectedOption, setSelectedOption] = useState(
    useSelector((state) => state.dashboard.option)
  );
  const [selectedDates, setSelectedDates] = useState([]);
  const[datePassed,setDatePassed]=useState([presentDate,last30Day])
  // const [selectedDates,setSelectedDates] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [shift, setShift] = useState(
    useSelector((state) => state.dashboard.shift)
  );
  const [distance, setDistance] = useState(
    useSelector((state) => state.graph.distanceTooltip)
  );
  const [usageTool, setUsageTool] = useState(
    useSelector((state) => state.graph.usageTooltip)
  );
  const [speed, setSpeed] = useState(
    useSelector((state) => state.graph.speedTooltip)
  );
  // values from redux
  const orgID = useSelector((state) => state.user.id);

  //rendering page after login, graph data is stored in redux

  const pickData = useSelector((state) => state.graph.pickSeries);
  const utilData = useSelector((state) => state.graph.utilSeries);
  const cateData = useSelector((state) => state.graph?.cate);

  const [dataLoaded, setDataLoaded] = useState(false);
    const [pickSeries, setPickSeries] = useState([]);
  const [utilSeries, setUtilSeries] = useState([]);
  const [usageSeries, setUsageSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.isAuth);
  const apiService = async (param) => {
    setSpinner(true);
    let liveDataEndPoint;
    liveDataEndPoint = `/graphs?id=${orgID}&startdate=${param.startDate}&enddate=${param.endDate}`;
    try {
      const response = await ApiService(liveDataEndPoint);
      if (response.status === 200) {
        const json = response.data;
        if (response.message === "NO DATA AVAILABLE FOR THE USER") {
          let cat = [presentDate];
          setCategories(response.date);
          setMessage(response.message);
        } else if (response.message === "Successfully retrived the data") {
          setMessage(response.message);
          setCategories(response.date);
          
          let pick = [];
          let distanceTravelled = [];
          let averageSpeed = [];
          let util = [];
          let usage = [];
          let usageTooltip = [];
          let utilTemp = {
            name: "1",
            data: json.utilization,
          };
          let pickTemp = {
            name: "2",
            data: json.pickvelocity,
          };
          let usageTemp = {
            name: "3",
            data: json.usage,
          };
          util.push(utilTemp);
          pick.push(pickTemp);
          usage.push(usageTemp);

          distanceTravelled.push(json.distancetravelled);
          averageSpeed.push(json.averagespeed);
          usageTooltip.push(json.usage);

          setPickSeries(pick);
          setUtilSeries(util);
          setUsageSeries(usage);
          setDistance(distanceTravelled);
          setSpeed(averageSpeed);
          setUsageTool(usageTooltip);
        }
      }
      setSpinner(false);
      // setClicked(false)
    } catch (error) {
      setSpinner(false);
      console.error("API Request Error:", error);
    }
  };

  const handleDateChange = (dates) => {
    const enddate = dayjs(dates[0]).add(20, "days").format("YYYY-MM-DD");
    if (dates[1] > enddate) {
      alert("Invalid Input: Dates must be within a 21-day range from start date.");
      setSelectedDates([]);
    } else {
      setSelectedDates(dates);
    }
  };
  const handleSubmit = async () => {
    setSpinner(true);
  
    if (selectedDates.length ===0) {
       alert("Choose another Date Range");  
      setSpinner(false);
      return;
    } else {
      param = {
        id: id,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
      setSelectedDates([]); 
    }
    setClicked(true);
    //changed the way the api is called
    apiService(param);
    setIsValid(true);
  };

  useEffect(() => {
    if (!dataLoaded) {
      apiService(param);
      setDataLoaded(true);
    }
  }, [param]);
  return (
    <div className="main-repo-body">
      {spinner && (
        <div style={{ display: "flex" }} id="main-alert-box">
          <span className="loader"></span>
        </div>
      )}
      <div className="analytics_vin">
        <RangeDatePicker
          onDateChange={handleDateChange}
          btnClicked={clicked}
          selectedDates={selectedDates}
          page={"Charts"}
          datePassed={datePassed}
        />
        <button id="submit" onClick={handleSubmit}>
          Plot Graph
        </button>
        {/* </div> */}
        {/* </div> */}
      </div>
      <div className="repo-graphs">
        <div className="analytics-data-two">
          {utilSeries && isValid ? (
            <Graphs
              name={"Avg Utilization %"}
              option={"util"}
              cat={categories}
              ser={utilSeries}
              tooltip={distance}
              message={message}
              days={param.selectedOption}
            />
          ) : (
            <NoDataDashboard text={""} />
          )}
        </div>
        <div className="analytics-data-three">
          {pickSeries && isValid ? (
            <Graphs
              name={"Avg Velocity %"}
              option={"velocity"}
              cat={categories}
              ser={pickSeries}
              tooltip={speed}
              message={message}
              days={param.selectedOption}
            />
          ) : (
            <NoDataDashboard text={""} />
          )}
        </div>
        <div className="analytics-data-four">
          {usageSeries && isValid ? (
            <Graphs
              name={"Avg Usage (min)"}
              option={"usage"}
              cat={categories}
              ser={usageSeries}
              tooltip={usageTool}
              message={message}
              days={param.selectedOption}
            />
          ) : (
            <NoDataDashboard text={""} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
