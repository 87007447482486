import React from "react";
import { DashboardHeader } from "../../Components/Dashboard/dashboardHeader/DashboardHeader";
import "./dashboard.css";
import { SideNavbar } from "../../Components/sideNavbar/SideNavbar";
import { Footer } from "../../Components/Footer/Footer";
import { DashboardBody } from "../../Components/Dashboard/DashboardBody";

export const Dashboard = () => {
  return (
    <div className="dashboard-body">
      <DashboardHeader />
      <div className="dashboard-page">
        <SideNavbar />
        <DashboardBody/>
      </div>

      <Footer />
    </div>
  );
};
