import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import active from "../assets/Active.svg";
import { useNavigate } from "react-router-dom";
import {
  selectActiveItem,
  setActiveItem,
  setToggleMenu,
  setSelectedNavMenu,
  selectSelectedNavMenu,
} from "../redux/slice/sideNavbarSlice";
const DropdownMenu = ({
  title,
  items,
  img,
  imgActive,
  defaultSelected,
  onMenuItemClick,
  toggle,
  className,
  user
}) => {
  const [isOpen, setIsOpen] = useState(onMenuItemClick ? false : true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);
  const toggleMenu = useSelector((state) => state.nav.toggleMenu);
  const catalystLogout=window.catalyst.auth;
  const username = useSelector((state) => state.user.name);

  useEffect(() => {
    // Update isOpen based on the toggle prop
    setIsOpen(toggle ? true : false);
  }, [toggle]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    //dispatch(setSelectedNavMenu("Dashboard"))
  };

  const handleClick = (index, event, item) => {

    event.preventDefault();
    dispatch(setActiveItem(index));
    dispatch(setToggleMenu(true));
    if (onMenuItemClick && index === 1) {
      onMenuItemClick(index);
    }
    if(username=="admin"){
     catalystLogout.signOut("/") 
    }else{                                                   
      localStorage.clear();
      navigate("/app")
    }
    
  };
  return (
      <button className={className} onClick={toggleDropdown}>
      <div>{user}</div>
    <div className="dropdown">
        <img
          src={isOpen ? imgActive : img}
          alt="dashboard logo"
          className={toggleMenu ? "icon" : "original-icon"}
        />
        {title}
      
      {isOpen && (
        <div className="dropdown-menu">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={(event) => handleClick(index, event, item)}
              className={"active"}
            >
              {/* <img
                src={active}
                className={index === activeItem ? "" : "active"}
              /> */}

              {item}
            </div>
          ))}
        </div>
      )}
    </div>
    </button>
  );
};

export default DropdownMenu;
