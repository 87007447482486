import React, { useEffect, useState } from "react";
import "./MoptroBody.css";
import Loader from "../Loader/Loader";
import fetchApiData from "../../service/appUtils";
import { useDispatch, useSelector } from "react-redux";
import { setMoptroData, setPickerDetail } from "../../redux/slice/moptroSlice";

export const MoptroBody = () => {
  const [data, setData] = useState(
    useSelector((state) => state.moptro.pickerDetail)
  );

  const [picker, setPicker] = useState(
    useSelector((state) => state.moptro.pickerDetail)
  );
  const [loader, setLoader] = useState(false);
  const [noData, setNodata] = useState(false);

  const orgName = useSelector((state) => state.user.name);
  const orgID = useSelector((state) => state.user.id);
  const id = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const param = {
    id: id,
  };

  //fetching the data from api
  const fetchData = async (id) => {
    const endpoint = `/moptrouser?id=${id}`;

    try {
      // Assuming fetchApiData is a function that wraps your API service
      const response = await fetchApiData(endpoint);

      if (response.status === 200) {
        setPicker(response.data);
        dispatch(setPickerDetail(response.data)); // Values from redux
      } else { 
        setLoader(false);
      }
      setLoader(false);

      return response;
    } catch (error) {
      console.error("API Request Error:", error);
      setLoader(false);
      setNodata(true);
    }
  };
  useEffect(() => {
    if (!picker) {
      setLoader(true);
      fetchData(orgID);
    }
  }, []);

  return (
    <div className="info-div">
      {loader && <Loader />}
      <div className="info-table">
        <div className="info-form">
          <div className="info-heading">Login details of Users <hr/></div>
         
          <div id="fixed_heading" className="info-report"> 
            <div>Picker Name</div>
            <div>Picker Id</div>
            <div>Password</div>
          </div>
          <div className="moptro-table">
            {noData && (
              <p style={{ textAlign: "center" }}>
                Picker data is not available
              </p>
            )}
            {picker &&
              picker.map((item, index) => (
                <div key={index} className="info-report">
                  <div>{item.picker_name}</div>
                  <div className="heading">{item.picker_id}</div>
                  <div className="password">{item.password}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
