import React, { useState } from "react";
import { Select } from "antd";
import "./select.css";
import { useDispatch } from "react-redux";
import { setShift } from "../../../redux/slice/shiftSlice";
import jsonData from "../../../users.json"

const SelectAntD = ({ selectedOption, name, report, dateRange,filterUser }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(name);
  let options;
  if (report) {
    options = [
      { value: "30Days", label: "Next 30 Days" },
      { value: "7Days", label: "Next 7 Days" },
      { value: "Last 30 Days", lable: "Last 30 Days",disabled:true },
    ];
  }
  if (dateRange) {
    options = [
      { value: "Daywise", label: "Daywise" },
      { value: "Date Range", label: "Date Range"},
    ];
  }
  const handleChange = (e) => {
    setValue(e);
    selectedOption(e);
    if (!report) {
      dispatch(setShift(e));
    }
  };

  return (
    <Select
      style={{ width: "100%", color: "white" }}
      placeholder={"Select"}
      onChange={handleChange}
      optionLabelProp="label"
      value={value}
      options={options}
      className="slectantd"
    ></Select>
  );
};

export default SelectAntD;
