import React from "react";
import "./loginFooter.css";
import greendzineLogo from "../../../assets/GreendzineLogo.svg";
export const LoginFooter = () => {
  return (
    <div className="login-footer">
      <img src={greendzineLogo} alt="Greendzine logo " />
      <div>
        A Product of Greendzine Technologies Pvt. Ltd. All rights reserved
      </div>
    </div>
  );
};
