import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../Dashboard/dashboard.css";
import "./moptro.css";
import { DashboardHeader } from "../../Components/Dashboard/dashboardHeader/DashboardHeader";
import { SideNavbar } from "../../Components/sideNavbar/SideNavbar";
import { Footer } from "../../Components/Footer/Footer";
import { MoptroBody } from "../../Components/Moptro/MoptroBody";

export const Moptro = () => {
  // const loggedInUser = localStorage.getItem("authenticated");

  return (
    <div className="dashboard-body">
      <DashboardHeader />
      <div className="dashboard-page">
        <SideNavbar />
        <div className="moptro-container">
          <MoptroBody />
        </div>
      </div>
      <Footer />
    </div>
  );
};
