import React, { useEffect } from "react";
import { LoginBody } from "../../Components/Login/LoginBody";

export const Login = () => {

  return (
    <div>
      <LoginBody />
    </div>
  );
};
