// Copyright @ 2023 Greendzine Technologies Pvt ltd. All rights reserved. For Internal use only.

import React, { useState } from "react";
import { Form, Select, DatePicker, TimePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "./timerender.css";
import "./select.css";
import { setDashShift, setDashDate } from "../../../redux/slice/dashboardSlice";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const TimeRangePicker = ({
  onDate,
   show,
  onStartTimeChange,
  onEndTimeChange,
  date,
}) => {
  const [selectedDate, setSelectedDate] = useState(date);
  const [value, setValue] = useState(
    useSelector((state) => state.dashboard.shift)
  );
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const dispatch = useDispatch();

  const [dates, setDates] = useState(null);
  const [DateValue, setDateValue] = useState(null);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[1] && dates[1].diff(current, "days") >= 7;
    const tooEarly = dates[0] && current.diff(dates[0], "days") >= 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const handleDateChange = (date) => {
    onDate(date);
    setSelectedDate(date);
    setDateValue(date);
    dispatch(setDashDate(date));

    if (show) {
      setStartTime(null);
      setEndTime(null);
    }
  };

  const disabledEndTime = (current) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }

    // Disable dates before JUNE 1st, 2023
    if (current && current < moment("2023-05-15").startOf("day")) {
      return true;
    }

    return false;
  };

  const handleEndTimeChange = (time) => {
    const date = new Date(time.$d);
    const temp = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setEndTime(time);
    onEndTimeChange(temp);
  };

  const handleStartTimeChange = (time) => {
    const date = new Date(time.$d);
    const temp = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setStartTime(time);
    setEndTime(null);
    onStartTimeChange(temp);
  };
  
  const today=dayjs()
  
  return (
    <div className="time_pick">
      <div className="temp_pick">
        <Form.Item
          label={"Start Date"}
          labelCol={{ style: { color: "#FFFFFF" } }}
        ></Form.Item>
        <DatePicker
          onChange={handleDateChange}
          value={selectedDate}
          className="custom-range-picker"
          disabledDate={disabledEndTime}
          showToday={false}
          format={"DD-MM-YYYY"}
          allowClear={false}
          placeholder={selectedDate?selectedDate:(today).format("DD-MM-YYYY")}
        />
      </div>
    </div>
  );
};

export default TimeRangePicker;
